import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, Showcase } from '../components/common'
import { Layout } from '../components/common/layout'
import { HomeHeader, HomeAPIBox, HomeFAQLink } from '../components/home'
import { Spirit } from '../styles/spirit-styles'
import { MetaData, getMetaImageUrls } from '../components/common/meta'

const HomePage = ({ data, location }) => {
    // Add meta title and description for this page here to overwrite the site meta data as set in the config
    const title = `多会文档`
    const description = `多会是专业的会议管理软件,活动管理系统,在线签到软件,现场签到系统,活动签到管理`
    const imageUrl = getMetaImageUrls()
    const latestUpdates = data.allMarkdownRemark.edges;

    // console.log(latestUpdates)

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
                title={title}
                description={description}
                image={imageUrl}
            />
            <Layout
                location={location}
                headerDividerStyle="shadow"
                bodyClass="bg-white"
                mainClass="bg-whitegrey-l2 pb-vw6 pb-vw3-ns"
                header={<HomeHeader location={location} />}
            >
                <div className="pt-vw3 home-main-box-padding-ns">
                    <div className={`${Spirit.page.xl} grid-12 gutter-row-20 gutter-40-ns`}>

                        <section className="col-12 col-6-ns flex flex-column justify-between mt4 mt0-ns">
                            <h3 className={`${Spirit.h3} link darkgrey flex-grow-0`}>功能介绍</h3>

                            <Box className="mt5 tdn flex-auto flex flex-column items-stretch" elevation="1">
                                <HomeAPIBox
                                    to="/config-guide/embedded-gadget/"
                                    title="嵌入式挂件功能"
                                    icon="computer"
                                >
                                    Embed Feature
                                </HomeAPIBox>
                                <HomeAPIBox
                                    to="/config-guide/web-hook/"
                                    title="WebHook 功能"
                                    icon="network"
                                >
                                    WebHook Feature
                                </HomeAPIBox>
                                <HomeAPIBox
                                    to="/knowledge-base/multi-session-feature/"
                                    title="多场次功能"
                                    icon="navigation"
                                >
                                    Multi-scene Feature
                                </HomeAPIBox>
                            </Box>
                        </section>

                        <section className="col-12 col-6-ns mt0-ns bt bn-ns b--whitegrey nl5 nr5 nl0-ns nr0-ns ml0-ns mr0-ns pl5 pr5 pl0-ns pr0-ns pt5 pt0-ns ">
                            <h3 className={`${Spirit.h3} link darkgrey`}>最近更新</h3>
                            <div className="mt3 mt7-ns">
                                {
                                    latestUpdates.map( (item, index) => {
                                        return (
                                            <div key={index}>
                                                <HomeFAQLink to={item.node.fields.slug} title={item.node.frontmatter.title}>
                                                    {item.node.excerpt }
                                                </HomeFAQLink>
                                            </div>
                                        )
                                    })
                                }
                                <a href="/product-update/" className={`${Spirit.p} midgrey fw5 link hover-blue`}>更多...</a>
                            </div>
                        </section>
                    </div>
                    <Showcase />
                </div>
            </Layout>
        </>
    )
}

HomePage.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                siteUrl: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default HomePage

export const pageQuery = graphql`
    query {
        site {
            ...SiteMetaFields
        }
        allMarkdownRemark(
            limit: 3,
            sort: { fields: [frontmatter___date], order: DESC },
            filter: { frontmatter: { sidebar: { in: ["product-update", "protocol", "knowledge-base", "features"]} } },
        ) {
            edges {
                node {
                    excerpt(format: PLAIN, pruneLength: 80, truncate: true)
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        heading
                    }
                }
            }
        }
    }
`
